<template>
  <div class="flex justify-center items-center my-4 mx-2">
    <div class="flex-col transaction-card">
      <h1 class="text-center">{{ getCurrencySymbol }} {{ amount }}</h1>
      <span class="text-center">Transaction Amount</span>
      <i class="fas fa-credit-card icon-custom"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PromoCard',
  components: {},
  data() {
    return {
      symbol: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getCurrencySymbol() {
      if (this.currencySymobol) return this.currencySymbol

      return this.organizationInfo?.default_currency?.symbol || '$'
    },
  },
  props: {
    amount: { required: false },
    text: { required: false },
    currencySymobol: { required: false },
  },
}
</script>

<style lang="scss" scoped>
.transaction-card {
  width: 574px;
  height: 150px;
  border-radius: 22px;
  background-color: #e9ffe1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  h1 {
    font-size: 37px;
    font-weight: bold;
    text-align: center;
    color: #00b225;
    // padding-top: 75px;
    // padding-bottom: 10px;
  }
  span {
    font-size: 19px;
    text-align: center;
    color: rgba(#0a0a0a, 0.53);
  }
  i {
    position: absolute;
    bottom: 10px;
    right: 15px;
    font-size: 50px;
    color: #c5fcb1;
  }
}
@media (max-width: 990px) {
  .transaction-card {
    i {
      font-size: 50px;
    }
  }
}
</style>
